import { graphql, PageProps } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { intl, t, textWithLocale } from '../../i18n'
import route from '../../utils/route'
import Layout from '../../components/Layout'
import NeumaticoCard from '../../components/FichasNeumaticos/Cards/NeumaticoCard'
import { makeNeumaticosSchema } from '../../utils/schema'
import {
  getModeloImagenes,
  getModeloMarca,
  getNeumaticoSpecs,
  neumaticoActivo,
  priceWithTax,
} from '../../components/FichasNeumaticos/utils'
import { useSelectedMenu } from '../../../../sites/rodi/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../../sites/rodi/constants/menu'
import ComprarNeumaticoCTA from '../../components/FichasNeumaticos/Ctas/ComprarNeumaticoCTA'
import styles from './fichas.module.scss'
import RequestTyreCTA from '../../components/FichasNeumaticos/Ctas/RequestTyreCTA'
import StandardModal from '../../components/modal/standardModal/StandardModal'
import RequestTyre from '../../components/RequestTyre/RequestTyre'
import { RequestTyreData } from '../../types/Neumatico'
import { Product, Promocion } from '../../context/reducers/cestaReducer'
import AppContext from '../../context/context'
import { productInfoFromId } from '../../api/cesta'
import { isPromocionForProductos } from '../../context/selectors/cestaSelectors'

const FichaNeumaticosPage = ({
  data: {
    modeloInfo,
    neumaticoInfo,
    seoData,
    banners_es_desktop,
    banners_ca_desktop,
    banners_pt_desktop,
    banners_es_mobile,
    banners_ca_mobile,
    banners_pt_mobile,
  },
}: PageProps<Queries.FichaNeumaticosPageQuery>) => {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)

  const {
    modelo,
    marca: { nombre: marca },
    imagenes,
    slug,
  } = modeloInfo

  const banners = {
    ca_desktop: banners_ca_desktop.nodes,
    ca_mobile: banners_ca_mobile.nodes,
    es_desktop: banners_es_desktop.nodes,
    es_mobile: banners_es_mobile.nodes,
    pt_desktop: banners_pt_desktop.nodes,
    pt_mobile: banners_pt_mobile.nodes,
  }
  const { fichas } = getModeloImagenes(imagenes)

  const [showModal, setShowModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [selectedNeumatico, setSelectedNeumatico] = useState(
    null as RequestTyreData | null
  )
  const [producto, setProducto] = useState({} as Product)
  const { userInfo } = useContext(AppContext)

  useEffect(() => {
    productInfoFromId(neumaticoInfo.id_site_producto.toString(), userInfo).then(
      (productWithInfo) => {
        setProducto(productWithInfo)
      }
    )
  }, [])

  const closeModal = () => {
    setSuccessMessage(false)
    setShowModal(false)
  }

  const isActive = neumaticoActivo(neumaticoInfo.producto)
  const price = isActive
    ? intl.formatNumber(priceWithTax(neumaticoInfo.producto), {
        style: 'currency',
        currency: 'EUR',
      })
    : undefined
  const PriceTaxPromotion =
    neumaticoInfo.promocion &&
    isPromocionForProductos(neumaticoInfo.promocion as unknown as Promocion)
      ? neumaticoInfo.importe - neumaticoInfo.promocion.importePromocion
      : priceWithTax(neumaticoInfo.producto)

  const CTAButton = isActive ? (
    <ComprarNeumaticoCTA
      neumaticoInfo={neumaticoInfo}
      marcaInfo={modeloInfo.marca}
    />
  ) : (
    <RequestTyreCTA
      modelo={modeloInfo}
      neumatico={neumaticoInfo}
      openModal={setShowModal}
      selectNeumatico={setSelectedNeumatico}></RequestTyreCTA>
  )

  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos.index'),
        },
        {
          display: marca,
          link: route('neumaticos.marca', {
            marca: marca.toLowerCase(),
          }),
        },
        {
          display: modelo,
          link: route('neumaticos.modelo', {
            marca: marca.toLowerCase(),
            modelo: slug,
          }),
        },
        {
          display: getNeumaticoSpecs(neumaticoInfo),
        },
      ]}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            makeNeumaticosSchema({
              name: `${getModeloMarca(modeloInfo)} ${getNeumaticoSpecs(
                neumaticoInfo
              )}`,
              image: fichas.length && fichas[0].url,
              description: modelo[textWithLocale('recomendado_para')],
              brand: marca,
              price: PriceTaxPromotion,
            })
          ),
        }}
      />

      <section className={styles.ficha_neumatico__container}>
        <section className={styles.ficha_neumatico__content}>
          <NeumaticoCard
            producto={producto}
            neumatico={neumaticoInfo}
            modelo={modeloInfo}
            banners={banners}
            cta={CTAButton}
          />
        </section>
      </section>
      <StandardModal
        title={t('landing_neumaticos.neumatico_item.disponibilidad')}
        closeModal={closeModal}
        showModal={showModal}>
        <RequestTyre
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          selectedNeumatico={selectedNeumatico}
        />
      </StandardModal>
    </Layout>
  )
}
export const pageQuery = graphql`
  query FichaNeumaticosPage(
    $idModelo: Int!
    $idSiteProductoNeumatico: Int!
    $url: String!
    $banners_es_desktop: String!
    $banners_ca_desktop: String!
    $banners_pt_desktop: String!
    $banners_es_mobile: String!
    $banners_ca_mobile: String!
    $banners_pt_mobile: String!
  ) {
    modeloInfo: modelos(id_site_modelo_neumatico: { eq: $idModelo }) {
      ...modeloInfo
      ...modeloMarca
      ...modeloImagen
    }

    neumaticoInfo: neumaticos(
      id_site_productos_neumaticos: { eq: $idSiteProductoNeumatico }
    ) {
      ...neumaticoInfo
      ...productoInfo
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: $banners_ca_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: $banners_ca_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: $banners_es_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: $banners_es_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_desktop: allS3Asset(
      filter: { Key: { glob: $banners_pt_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_mobile: allS3Asset(
      filter: { Key: { glob: $banners_pt_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`

export default FichaNeumaticosPage
